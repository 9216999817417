section {
  padding: 11em 0;
}

.bg-white {
  background-color: $white;
  color: $grey;

  h2 {
    color: $ink;
  }

  h3 {
    color: $grey;
  }
}

.bg-ink {
  background-color: $ink;
  color: $white;

  h2,
  h3 {
    color: $white;
  }
}

.bg-grey {
  background-color: $grey-web;
  color: $grey;

  h2 {
    color: $ink;
  }

  h3 {
    color: $grey;
  }
}

.legal-section {
  padding: 4em 0;
}

.container {
  max-width: $container-width;
  margin: 0 auto 6em;
  padding: 0 2em;

  &:last-child {
    margin-bottom: 0;
  }

  &-large {
    max-width: $container-width-wide;
  }
}

.centered {
  text-align: center;
}
