* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: $font-family;
  color: $grey;

  img {
    max-width: 100%;
  }

  @media screen and (max-width: $small) {
    font-size: 50%;
  }
}

h1,
h2,
h3 {
  font-family: $font-family-heading;
}

h1 {
  font-size: 6em;
  line-height: 1.2;
  @media screen and (max-width: $small) {
    font-size: 5em;
  }
}
h2 {
  font-size: 5em;
  line-height: 1.2;
  @media screen and (max-width: $small) {
    font-size: 3em;
  }
}
h3 {
  font-size: 2.6em;
  line-height: 1.5;
  @media screen and (max-width: $small) {
    font-size: 2em;
  }
}

li {
  font-size: 2.4em;
  line-height: 1.5;
  @media screen and (max-width: $small) {
    font-size: 1.8;
  }
}

p {
  font-size: 2.4em;
  line-height: 1.5;
  @media screen and (max-width: $small) {
    font-size: 1.8;
  }

  &.legal {
    font-size: 1.2em;
    line-height: 1.5;
    @media screen and (max-width: $small) {
      font-size: 0.8;
    }
  }
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
p,
li {
  margin: 0 0 1em;
  &:last-child {
    margin-bottom: 0;
  }
}

.cta-connect {
  margin-top: 2em;
}

.button {
  display: inline-block;
  background-color: $blue;
  color: $white;
  border: 2px solid transparentize($white, 1);
  padding: 0.4em 1.2em;
  font-weight: bold;
  transition: color $timing $duration, background-color $timing $duration,
    border-color $timing $duration;

  &:hover {
    background-color: transparentize($white, 1);
    color: $white;
    border-color: $white;
  }
}
