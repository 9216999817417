header.nav-header {
  position: fixed;
  width: 100%;
  height: $header-height;
  background: $white;
  top: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: $container-width-wide;
  margin: 0 auto;
  padding: 1.5em 2.5rem;

  li {
    list-style: none;
  }

  .logo {
    width: 10em;
  }

  &__logo {
    color: $turquoise;
    width: 100%;
    margin-bottom: 0;

    svg {
      max-width: 100%;
    }
  }

  &__menu {
    .button {
      font-size: 0.7em;
      padding: 0.4em 1.2em;

      // TODO: ALIGNMENT

      &:hover {
        color: $turquoise;
        border-color: $turquoise;
      }
    }
  }

  @media screen and (max-width: $medium) {
    .nav-bar__menu {
      .button {
        font-size: 0.7em;
        padding: 0.4em 1.2em;
      }
    }
  }

  @media screen and (max-width: $small) {
    .nav-bar__menu {
      .button {
        font-size: 0.6em;
      }
    }
  }
}
