$white: #fff;
$black: #000;
$blue: #00819d;
$turquoise: #00a9ce;
$ink: #201747;
$grey: #424242;
$grey-web: #edf0f2;
$grey-legal: #eae9e9;

$container-width-wide: 1300px;
$container-width: 1000px;

$header-height: 7em;

$font-family-heading: "Larsseit", sans-serif;
$font-family: "TTNormsPro", sans-serif;

$timing: 0.3s;
$duration: ease-out;

// https://www.freecodecamp.org/news/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862/
$small: 37.5em;
$medium: 56.25em;
$large: 75em;
$xlarge: 112.5em;
$minHeight: 37em;
