.hero {
  background-image: url("../img/hero.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: $header-height;
  color: $white;

  .container {
    padding-top: 3vh;
    padding-bottom: 3vh;
  }

  &__inner {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 70%;
  }

  .eyebrow-text {
    margin-bottom: 2.4em;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .eyebrow {
    height: 1em;
    width: 8em;
    background-color: $blue;
    margin: 4em 0;
  }

  @media screen and (max-width: $large) {
    background-position: 80% center;

    &__inner {
      // align-items: center;
      width: 100%;
    }
  }

  @media screen and (max-width: $medium) {
    background-position: 80% center;

    .container {
      padding-top: 7em;
      padding-bottom: 7em;
    }
  }

  @media screen and (max-width: $small) {
    background-position: 80% center;
  }

  /** IE hack */
  .no-flexboxlegacy & {
    display: block;
  }
}
