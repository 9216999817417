.grid {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  // align-items: flex-start;
  align-items: stretch;

  .grid-item {
    width: calc(1 / 3 * 100%);
    margin: 1.2em;
    text-align: center;

    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  .grid-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10em;
    margin-bottom: 3em;
  }

  .grid-content {
    display: flex;
    flex-flow: column;
    // justify-content: space-between;
    justify-content: flex-start;
    height: 100%;
  }

  img,
  svg {
    width: 100%;
    max-width: 7em;
  }

  p {
    font-size: 1.8em;
    line-height: 1.5;
  }

  @media screen and (max-width: $medium) {
    flex-flow: column;
    align-items: center;

    .grid-item {
      width: 50%;
      margin: 1.2em auto 3em;
    }
  }
  @media screen and (max-width: $small) {
    .grid-item {
      width: 80%;
    }
  }
}
